import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/pathologies",
  all: []
}

// getters
const getters = {
}

// actions
const actions = {
  getAllPathologies ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setPathologies', data.data))
  }
}

// mutations
const mutations = {
  setPathologies (state, pathologies) {
    state.all = pathologies
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
