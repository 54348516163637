import axios from 'axios'
import randomSeed from 'random-seed'
import { flatMap, isEmpty } from 'lodash'

// initial state
const state = {
  endpoint: "/api/v1/practitioners",
  all: [],
  loading: false
}

// getters
const getters = {
  practitionerMarkers (state) {
    let practitioners = state.all.map((practitioner) => {
      let address = practitioner.attributes.address
      if (!address) {
        return null
      }

      // Use a seeded random generator to always have the same placement for each marker
      let rand = randomSeed.create()
      rand.seed(practitioner.id)
      let latOffset = (rand(150) - 75) / 1000000
      let lngOffset = (rand(300) - 150) / 1000000

      let position = {
        lat: address.attributes.latitude + latOffset,
        lng: address.attributes.longitude + lngOffset
      }

      let offices = practitioner.attributes.offices.data

      if (!isEmpty(offices)) {
        return offices.map(office => {
          let address = office.attributes.address
          if (!address) {
            return false
          }

          let position = {
            lat: address.attributes.latitude + latOffset,
            lng: address.attributes.longitude + lngOffset
          }

          return {
            practitioner,
            address,
            position,
            office
          }
        })
      }

      return {
        practitioner,
        address,
        position
      }
    }).filter(Boolean)

    return flatMap(practitioners)
  },

  practitionerWithOffices (state) {
    let practitioners = state.all.map(practitioner => {
      let offices = practitioner.attributes.offices.data
      // Use a seeded random generator to always have the same placement for each marker
      let rand = randomSeed.create()
      rand.seed(practitioner.id)
      let latOffset = (rand(150) - 75) / 1000000
      let lngOffset = (rand(300) - 150) / 1000000

      if (!isEmpty(offices)) {
        return offices.map(office => {
          let address = office.attributes.address
          if (!address) {
            return false
          }

          let position = {
            lat: address.attributes.latitude + latOffset,
            lng: address.attributes.longitude + lngOffset
          }

          return {
            practitioner,
            address,
            position,
            office
          }
        })
      }

      let address = practitioner.attributes.address
      if (!address) {
        return false
      }

      let position = {
        lat: address.attributes.latitude + latOffset,
        lng: address.attributes.longitude + lngOffset
      }

      return {
        practitioner,
        address,
        position
      }
    })

    return flatMap(practitioners)
  }
}

// actions
const actions = {
  getAllPractitioners ({ commit }, params) {
    commit('setLoading', true)
    axios.get(state.endpoint, { params }).then(({ data }) => {
      commit('setPractitioners', data.data)
      commit('setLoading', false)
    })
  },

  resetStateAll (context) {
    context.commit('resetStateAll')
  }
}

// mutations
const mutations = {
  setPractitioners (state, practitioners) {
    state.all = practitioners
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  resetStateAll (state) {
    state.all = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
