export default class NavFixed {
  constructor () {
    // change styleguide header display on scroll
    this.$header = $('[data-is-nav-scroll]')
    this.bannerAlert = document.querySelector('.promotional-header__close')
    this.scrollLevelMax = window.innerHeight - 220 // header + nav height
    this.checkScrollLevel()
    this.bindEvents()
  }

  // check scroll level : know which class header should have
  checkScrollLevel () {
    if ($(window).scrollTop() > this.scrollLevelMax) {
      return this.$header.addClass('nav--fixed')
    } else {
      return this.$header.removeClass('nav--fixed')
    }
  }

  // call checkScrollLevel() on scroll
  bindEvents () {
    const self = this
    if (this.bannerAlert) {
      const navSecondary = document.querySelector('.nav__secondary--sticky')
      const mainWrapper = document.querySelector('.main-wrapper')
      if (navSecondary) {
        navSecondary.style.top = "13.8rem"
        this.bannerAlert.addEventListener('click', function () {
          navSecondary.style.top = "7.5rem"
        })
      }
      if (mainWrapper) {
        mainWrapper.classList.add("promotional-banner-active")
        this.bannerAlert.addEventListener('click', function () {
          mainWrapper.classList.remove("promotional-banner-active")
        })
      }
    }
    return $(window).scroll(() => self.checkScrollLevel())
  }
}
