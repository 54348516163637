import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/referents",
  all: [],
  initialState: [],
  newReferent: {
    id: null,
    attributes: {
      user_attributes: {
        id: '',
        firstname: '',
        lastname: '',
        email: ''
      },
      phone: '',
      function: '',
      user_id: '',
      errors: [],
      company_id: '',
      user: {
        attributes: {}
      }
    }
  }
}

// getters
const getters = {
}

// actions
const actions = {
  getAllReferents ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setReferents', data.data))
  },

  resetStateAll (context) {
    context.commit('resetStateAll')
  },

  createReferent ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.post(state.endpoint, {
        referent: params.referent.attributes
      }).then(({ data }) => {
        let referent = data.data
        commit('insertReferent', referent)
        resolve(referent)
      }).catch(({ response }) => {
        let referent = response.data.data
        reject(referent)
      })
    })
  }
}

// mutations
const mutations = {
  setReferents (state, referents) {
    state.all = referents
  },

  resetStateAll (state) {
    state.all = []
  },

  insertReferent (state, newReferent) {
    let index = state.all.findIndex((referent) => {
      return referent.id === newReferent.id
    })

    if (index === -1) {
      state.all.push(newReferent)
    } else {
      state.all.splice(index, 1, newReferent)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
