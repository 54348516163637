// #############################
// CUSTOM FILE INPUT LIST
// #############################

// ######## GENERATE EACH CUSTOM FILE INPUT
import CustomFileInput from "@appComponents/helpers/custom-file-input"

export default class CustomFileInputList {
  constructor () {
    $("[data-is-custom-file-input-wrapper]").each(function () {
      new CustomFileInput($(this))
    })
  }
}
