export default class Readmore {
  constructor () {
    this.selector = '[data-is-readmore]'
    this.triggerSelector = '[data-trigger-readmore]'
    this.closedHeight = '155'
    this.disabledClass = 'readmore--disabled' // add disabledClass if element is too small to create readmore functioning

    this.init()
    this.bindEvents()
  }

  getNaturalHeight (elt) {
    return elt.outerHeight()
  }

  // get natural height
  // if element is height enough, collapse element.
  // else, add disabledClass
  init () {
    const self = this

    $(this.selector).each(function () {
      let naturalHeight = self.getNaturalHeight($(this))
      if (naturalHeight > self.closedHeight) {
        $(this).attr('data-height', self.getNaturalHeight($(this)))
        $(this).attr('data-open', 'false')
        self.setHeight($(this), self.closedHeight + 'px')
      } else {
        $(this).addClass(self.disabledClass)
      }
    })
  }

  // on click, open/close element
  bindEvents () {
    const self = this
    $(this.triggerSelector).click(function (evt) {
      evt.preventDefault()
      var newHeight = 0
      const parent = $(this).parents(self.selector)
      if (parent.attr('data-open') === 'false') {
        newHeight = parent.attr('data-height') + 'px'
        self.setOpenStatus(parent, true)
      } else {
        newHeight = self.closedHeight + 'px'
        self.setOpenStatus(parent, false)
      }
      self.setHeight(parent, newHeight)
    })
  }

  setHeight (elt, height) {
    elt.css('height', height)
  }

  setOpenStatus (elt, openStatus) {
    if (openStatus) {
      elt.addClass('open')
      elt.attr('data-open', 'true')
    } else {
      elt.removeClass('open')
      elt.attr('data-open', 'false')
    }
  }
}
