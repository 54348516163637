import SliderPaginate from "./slider-paginate"
// ####################
// ## SLIDERS
// ################################################################################
// # for each slider found in the markup, init one Slider
export default class SlidersPaginate {
  constructor () {
    const self = this

    this.attribute = 'data-is-slider-paginate'
    this.selector = `[${this.attribute}]`

    if ($(document).find(self.selector).length > 0) {
      self.init()
    }
  }

  init () {
    const self = this
    $(self.selector).each(function () {
      return new SliderPaginate($(this))
    })
  }
}
