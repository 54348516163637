import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/patients",
  isLoading: false,
  all: [],
  newPatient: {
    id: null,
    attributes: {
      firstname: '',
      lastname: '',
      contact_email: '',
      phone: '',
      user_id: '',
      patient_full_name: '',
      employee_classification: '',
      errors: [],
      address: {
        attributes: {}
      }
    }
  }
}

// getters
const getters = {
}

// actions
const actions = {
  getAllPatients ({ commit }, params) {
    commit('setLoading', true)

    axios.get(state.endpoint, { params }).then(({ data }) => {
      commit('setPatients', data.data)
      commit('setLoading', false)
    })
  },

  resetStateAll (context) {
    context.commit('resetStateAll')
  },

  createPatient ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.post(state.endpoint, {
        patient: params.patient.attributes,
        site_id: params.site_id
      }).then(({ data }) => {
        let patient = data.data
        commit('insertPatient', patient)
        resolve(patient)
      }).catch(({ response }) => {
        let patient = response.data.data
        reject(patient)
      })
    })
  }
}

// mutations
const mutations = {
  initializeNewPatient (state, query) {
    if (query.indexOf('@') > -1) {
      state.newPatient.attributes.contact_email = query
    } else if (query) {
      if (query.indexOf(' ') > -1) {
        state.newPatient.attributes.lastname = query.substring(0, query.lastIndexOf(' '))
        state.newPatient.attributes.firstname = query.substring(query.lastIndexOf(' '), query.length)
      } else {
        state.newPatient.attributes.lastname = query
      }
    }
  },

  setLoading (state, loading) {
    state.isLoading = loading
  },

  setPatients (state, patients) {
    state.all = patients
  },

  insertPatient (state, newPatient) {
    let index = state.all.findIndex((patient) => {
      return patient.id === newPatient.id
    })

    if (index === -1) {
      state.all.push(newPatient)
    } else {
      state.all.splice(index, 1, newPatient)
    }
  },

  resetStateAll (state) {
    state.all = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
