import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/trainees",
  all: [],
  initialState: [],
  newTrainee: {
    id: null,
    attributes: {
      user_attributes: {
        id: '',
        firstname: '',
        lastname: '',
        email: ''
      },
      address: {
        attributes: {}
      },
      phone: '',
      user_id: '',
      errors: [],
      user: {
        attributes: {}
      }
    }
  }
}

// getters
const getters = {
}

// actions
const actions = {
  getAllTrainees ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setTrainees', data.data))
  },

  resetStateAll (context) {
    context.commit('resetStateAll')
  },

  createTrainee ({ commit }, params) {
    params.trainee.attributes.address_attributes = params.trainee.attributes.address.attributes
    delete params.trainee.attributes.address.attributes

    return new Promise((resolve, reject) => {
      axios.post(state.endpoint, {
        trainee: params.trainee.attributes
      }).then(({ data }) => {
        let trainee = data.data
        commit('insertTrainee', trainee)
        resolve(trainee)
      }).catch(({ response }) => {
        let trainee = response.data.data
        reject(trainee)
      })
    })
  }
}

// mutations
const mutations = {
  setTrainees (state, trainees) {
    state.all = trainees
  },

  resetStateAll (state) {
    state.all = []
  },

  insertTrainee (state, newTrainee) {
    let index = state.all.findIndex((trainee) => {
      return trainee.id === newTrainee.id
    })

    if (index === -1) {
      state.all.push(newTrainee)
    } else {
      state.all.splice(index, 1, newTrainee)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
