export default class NavActive {
  // constructor
  constructor () {
    this.menuWrapper = $('[data-is-nav]')
    this.menuLinks = this.menuWrapper.find('li')

    // change position arrow on hover
    this.bindEvents()
  }

  // bind events
  bindEvents () {
    const self = this

    // on scroll
    return $(window).on('scroll', function () {
      self.scrollLevel()
    })
  }

  // scroll level
  scrollLevel () {
    const self = this

    const scrollPos = $(window).scrollTop()

    return this.menuLinks.each(function () {
      const currLink = $(this)
      const refElement = $(currLink.find('a[href^="#"]').attr('href'))

      if (refElement.length === 0) {
        return false
      }

      if (((refElement.offset().top) - 175 <= scrollPos) && ((refElement.offset().top + refElement.outerHeight()) > scrollPos)) {
        self.menuLinks.removeClass('active')
        currLink.addClass('active')
      } else {
        return currLink.removeClass('active')
      }
    })
  }
}
