import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/companies",
  all: []
}

// getters
const getters = {
  hasCompanies (state) {
    return state.all.length > 0
  }
}

// actions
const actions = {
  getAllCompanies ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setCompanies', data.data))
  }
}

// mutations
const mutations = {
  setCompanies (state, companies) {
    state.all = companies
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
