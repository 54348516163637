// ///////////////////
// STICKY SCROLL
// ///////////////////
export default class StickyScroll {
  constructor () {
    if (document.querySelectorAll('[data-is-sticky]').length) {
      this.$stickElement = $('[data-is-sticky]')
      this.$scrollEnd = $('[data-trigger-sticky-end]')
      this.fixedClass = 'stick-fixed'
      this.endClass = 'stick-bottom'
      this.fluidClass = 'stick-natural'
      this.offset = this.$stickElement.data('is-sticky')
      this.margin = this.$stickElement.data('is-sticky')
      this.offtop = 0
      this.offbtm = 0
      this.offleft = 0

      this.calcOffset()
      this.bindEvents()
    }
  }

  calcOffset () {
    this.offtop = this.$stickElement.offset().top - this.margin
    this.offbtm = this.$scrollEnd.offset().top - (this.$stickElement.outerHeight()) - 210
    this.offleft = this.$stickElement.offset().left
  }

  setOffBtm () {
    this.offbtm = this.$scrollEnd.offset().top - (this.$stickElement.outerHeight()) - 210
  }

  reinitStick () {
    const self = this
    this.$stickElement.removeClass(self.endClass).removeClass(self.fixedClass).addClass(self.fluidClass).css({
      'top': 'auto',
      'left': 'auto'
    })
    this.calcOffset()
    this.calcStickPosition()
  }

  calcStickPosition () {
    const self = this
    let scrtop = $(window).scrollTop()

    if (scrtop > this.offtop && this.$stickElement.hasClass(self.fluidClass)) {
      this.$stickElement.removeClass(self.fluidClass).addClass(self.fixedClass).css('top', self.margin)
    }
    if (this.offtop > scrtop && this.$stickElement.hasClass(self.fixedClass)) {
      this.$stickElement.removeClass(self.fixedClass).addClass(self.fluidClass).css('top', 'auto')
    }
    if (scrtop > this.offbtm && this.$stickElement.hasClass(self.fixedClass)) {
      this.$stickElement.removeClass(self.fixedClass).addClass(self.endClass).css('top', self.offbtm - self.offtop)
    }
    if (this.offbtm > scrtop && this.$stickElement.hasClass(self.endClass)) {
      this.$stickElement.removeClass(self.endClass).addClass(self.fixedClass).css('top', self.margin)
    }
    if (this.$stickElement.hasClass(self.fixedClass)) {
      this.$stickElement.css('left', (this.offleft - $(window).scrollLeft()) + 'px')
    } else {
      this.$stickElement.css('left', 'auto')
    }
    this.setOffBtm()
  }

  bindEvents () {
    const self = this
    let windowWidth = $(window).width()
    $(window).resize(function (e) {
      if (windowWidth !== $(window).width()) {
        windowWidth = $(window).width
        self.reinitStick()
      }
    })

    $(window).scroll(function () {
      self.calcStickPosition()
    })

    /// quick fix : stick element can change height on click
    this.$stickElement.on("click", function (e) {
      setTimeout(() => {
        self.setOffBtm()
        self.calcStickPosition()
      }, 400)
    })
  }
}
