export default class SliderPaginate {
  constructor (item, options) {
    this.$wrapper = item

    if (this.$wrapper.length === 0) {
      return
    }

    this.$slider = this.$wrapper.find('[data-is-slider]')
    this.$currentPagination = this.$wrapper.find('[data-is-current-slide-index]')
    this.$totalPagination = this.$wrapper.find('[data-is-total-slide-number]')

    if (this.$slider.length === 0 || this.$currentPagination.length === 0 || this.$totalPagination.length === 0) {
      return
    }

    this.init()
    this.bindEvents()
  }

  init () {
    const self = this
    this.$currentPagination.text(1)
    this.$totalPagination.text(self.$slider.slick('getSlick').slideCount)
  }

  bindEvents () {
    const self = this
    this.$slider.on('afterChange', function (slick, currentSlide) {
      self.$currentPagination.text(currentSlide.currentSlide + 1)
    })
  }
}
