import axios from 'axios'
import randomSeed from 'random-seed'

// initial state
const state = {
  endpoint: "/api/v1/practitioner_addresses",
  all: [],
  selectedAddress: {
    attributes: {}
  },
  loading: false
}

// getters
const getters = {
  practitioners: (state) => {
    let practitioners = []

    state.all.forEach((address) => {
      address.attributes.practitioners.forEach(practitioner => {
        practitioner.address = address
        practitioners.push(practitioner)
      })
    })

    return practitioners
  },

  markers: (state) => {
    return state.all.map((address) => {
      // Use a seeded random generator to always have the same placement for each marker
      let rand = randomSeed.create()
      rand.seed(address.id)

      let icon = require('images/map/map-marker.png')

      if (address.id === state.selectedAddress.id) {
        icon = require('images/map/map-marker-active.png')
      } else if (address.attributes.addressable_type === "Profiles::Practitioner") {
        icon = require('images/map/map-marker-yellow.png')
      }

      let latOffset = (rand(200) - 100) / 1000000
      let lngOffset = (rand(400) - 200) / 1000000
      let randomizedPosition = {
        lat: address.attributes.latitude + latOffset,
        lng: address.attributes.longitude + lngOffset
      }

      return {
        address,
        icon,
        position: randomizedPosition
      }
    })
  }
}

// actions
const actions = {
  getAllPractitionerAddresses ({ commit }, params) {
    commit('setLoading', true)
    axios.get(state.endpoint, { params }).then(({ data }) => {
      commit('setPractitionerAddresses', data.data)
      commit('setLoading', false)
    })
  },

  selectAddress ({commit}, address) {
    commit('selectAddress', address)
  }
}

// mutations
const mutations = {
  setPractitionerAddresses (state, practitioners) {
    state.all = practitioners
  },

  setLoading (state, loading) {
    state.loading = loading
  },

  selectAddress (state, address) {
    state.selectedAddress = address
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
