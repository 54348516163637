export default class NavSecondaryFixed {
  constructor () {
    this.selector = '[data-is-nav-secondary-scroll]'
    if ($(document).find(this.selector).length > 0) {
      this.bannerAlert = document.querySelector('.promotional-header__close')
      this.$headerSecondary = $(this.selector)
      this.scrollLevelMax = this.$headerSecondary[0].getBoundingClientRect().top + window.scrollY - 75 // 75 : header height
      this.checkSecondaryScrollLevel()
      this.bindEvents()
    }
  }

  // check scroll level : know which class header should have
  checkSecondaryScrollLevel () {
    if ($(window).scrollTop() > this.scrollLevelMax) {
      return document.querySelector('.promotional-header__close') ? this.$headerSecondary.addClass('nav--fixed--lg') : this.$headerSecondary.addClass('nav--fixed')
    } else {
      return document.querySelector('.promotional-header__close') ? this.$headerSecondary.removeClass('nav--fixed--lg') : this.$headerSecondary.removeClass('nav--fixed')
    }
  }

  // call checkScrollLevel() on scroll
  bindEvents () {
    const self = this
    if (this.bannerAlert) {
      const navSecondary = document.querySelector('.nav__secondary')
      if (navSecondary) {
        this.bannerAlert.addEventListener('click', function () {
          navSecondary.classList.replace('nav--fixed--lg', 'nav--fixed')
        })
      }
    }
    return $(window).scroll(() => self.checkSecondaryScrollLevel())
  }
}
