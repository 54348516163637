import axios from "axios"
import FlashMessage from "src/shared/components/flash-messages"

function flashHeadersMessage (response) {
  if (response.headers['x-message']) {
    let content = JSON.parse(response.headers['x-message'])
    let message = decodeURIComponent(escape(content.message))
    new FlashMessage(message, content.type)
    return true
  } else {
    return false
  }
}

function handle (response) {
  flashHeadersMessage(response)

  return response
}

function handleError (error) {
  if (!flashHeadersMessage(error.response)) {
    if (error.response.status === 401) {
      new FlashMessage(`Erreur ${error.response.status}: Vous n'êtes pas autorisé à effectuer cette action`, "danger")
    } else {
      new FlashMessage(`Erreur ${error.response.status}: Une erreur s'est produite`, "danger")
    }
  }

  return Promise.reject(error)
}

axios.interceptors.response.use(response => handle(response), error => handleError(error))
