import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/sessions",
  all: []
}

// getters
const getters = {
  sessionsWithSlots (state) {
    return state.all.filter(session => session.attributes.slots.length > 0)
  },

  getSessionById: (state) => (id) => {
    return state.all.find(session => session.id === id)
  }
}

// actions
const actions = {
  getAllSessions ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setSessions', data.data))
  }
}

// mutations
const mutations = {
  setSessions (state, sessions) {
    state.all = sessions
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
