export default class Tooltip {
  constructor (options) {
    if (options == null) { options = {} }
    const defaultOptions = {
      placement: 'bottom'
    }

    this.options = $.extend(true, {}, defaultOptions, options)

    this.init()
  }

  init () {
    const self = this
    return $('body').find('[data-toggle="tooltip"]').tooltip(self.options)
  }
}
