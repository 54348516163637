import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/weekly_availabilities",
  all: [],
  showApplyWeeklyPlanningBtn: false,
  newWeeklyAvailability: {
    id: null,
    attributes: {
      wday: '',
      start_time: '',
      end_time: '',
      practitioner_office_id: '',
      appointments_number: 1,
      errors: []
    }
  }
}

// getters
const getters = {
}

// actions
const actions = {
  getAllWeeklyAvailabilities ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setWeeklyAvailabilities', data.data))
  },

  createWeeklyAvailability ({ commit }, weeklyAvailability) {
    return new Promise((resolve, reject) => {
      axios.post(state.endpoint, {
        weekly_availability: weeklyAvailability.attributes
      }).then(({ data }) => {
        weeklyAvailability = data.data
        commit('insertWeeklyAvailability', weeklyAvailability)
        commit('setShowApplyWeeklyPlanningBtn', true)
        resolve(weeklyAvailability)
      }).catch(({ response }) => {
        weeklyAvailability = response.data.data
        reject(weeklyAvailability)
      })
    })
  },

  updateWeeklyAvailability ({ commit }, weeklyAvailability) {
    return new Promise((resolve, reject) => {
      axios.put(`${state.endpoint}/${weeklyAvailability.id}`, {
        weekly_availability: weeklyAvailability.attributes
      }).then(({ data }) => {
        weeklyAvailability = data.data
        commit('insertWeeklyAvailability', weeklyAvailability)
        commit('setShowApplyWeeklyPlanningBtn', true)
        resolve(weeklyAvailability)
      }).catch(({ response }) => {
        weeklyAvailability = response.data.data
        reject(weeklyAvailability)
      })
    })
  },

  deleteWeeklyAvailability ({ commit }, weeklyAvailability) {
    axios.delete(`${state.endpoint}/${weeklyAvailability.id}`)
      .then(() => {
        commit('deleteWeeklyAvailability', weeklyAvailability)
        commit('setShowApplyWeeklyPlanningBtn', true)
        commit('setEditableWeeklyAvailability', undefined)
      })
  },

  selectWeeklyAvailability ({ commit }, weeklyAvailability) {
    commit('setEditableWeeklyAvailability', weeklyAvailability)
  }
}

// mutations
const mutations = {
  setWeeklyAvailabilities (state, weeklyAvailabilities) {
    state.all = weeklyAvailabilities
  },

  insertWeeklyAvailability (state, newWeeklyAvailability) {
    let index = state.all.findIndex((weeklyAvailability) => {
      return weeklyAvailability.id === newWeeklyAvailability.id
    })

    if (index === -1) {
      state.all.push(newWeeklyAvailability)
    } else {
      state.all.splice(index, 1, newWeeklyAvailability)
    }
  },

  deleteWeeklyAvailability (state, oldWeeklyAvailability) {
    let index = state.all.findIndex((weeklyAvailability) => weeklyAvailability.id === oldWeeklyAvailability.id)

    state.all.splice(index, 1)
  },

  setEditableWeeklyAvailability (state, weeklyAvailability) {
    state.editableWeeklyAvailability = weeklyAvailability
  },

  setShowApplyWeeklyPlanningBtn (state) {
    state.showApplyWeeklyPlanningBtn = state
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
