import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/trainings",
  all: [],
  initialState: []
}

// getters
const getters = {
}

// actions
const actions = {
  getAllTrainings ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setTrainings', data.data))
  },

  resetStateAll (context) {
    context.commit('resetStateAll')
  }
}

// mutations
const mutations = {
  setTrainings (state, trainings) {
    state.all = trainings
  },

  resetStateAll (state) {
    state.all = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
