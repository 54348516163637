export default class MobileNav {
  constructor () {
    this.cookiesAlert = document.querySelector('.cookies-alert')
    this.mobileNav = document.querySelector('[data-is-mobile-nav]')
    this.init()
  }

  init () {
    if (this.cookiesAlert) {
      const dismissCookies = this.cookiesAlert.querySelector('[data-dismiss]')
      dismissCookies.addEventListener('click', () => {
        this.displayMobileNav()
      })
    } else {
      this.displayMobileNav()
    }
  }

  displayMobileNav () {
    if (this.mobileNav) {
      return this.mobileNav.classList.add('d-flex')
    }
  }
}
