export default class HomeLogo {
  constructor () {
    this.$logo = $('[data-is-logo]')
    this.bindEvents()
  }

  // bind events
  bindEvents () {
    // on scroll
    $(window).scroll(function () {
      $('.hero__logo').css("opacity", 1 - $(window).scrollTop() / 5000)
      $('.hero__logo').css("width", 550 - $(window).scrollTop() / 35)
    })
  }
}
