import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/enrollments",
  all: [],
  newEnrollment: {
    id: null,
    attributes: {
      training_session_id: null,
      profiles_trainee_id: null,
      admin_note: ''
    }
  }
}

// getters
const getters = {
}

// actions
const actions = {
  createEnrollment ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.post(state.endpoint, {
        as: params.as,
        enrollment: params.enrollment.attributes
      }).then(({ data }) => {
        let enrollment = data.data
        resolve(enrollment)
      }).catch(({ response }) => {
        let enrollment = response.data.data
        reject(enrollment)
      })
    })
  }
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
