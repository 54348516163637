import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/trainers",
  all: [],
  initialState: [],
  newTrainer: {
    id: null,
    attributes: {
      user_attributes: {
        id: '',
        firstname: '',
        lastname: '',
        email: ''
      },
      phone: '',
      user_id: '',
      errors: [],
      user: {
        attributes: {}
      }
    }
  }
}

// getters
const getters = {
}

// actions
const actions = {
  getAllTrainers ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setTrainers', data.data))
  },

  resetStateAll (context) {
    context.commit('resetStateAll')
  },

  createTrainer ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.post(state.endpoint, {
        trainer: params.trainer.attributes
      }).then(({ data }) => {
        let trainer = data.data
        commit('insertTrainer', trainer)
        resolve(trainer)
      }).catch(({ response }) => {
        let trainer = response.data.data
        reject(trainer)
      })
    })
  }
}

// mutations
const mutations = {
  setTrainers (state, trainers) {
    state.all = trainers
  },

  resetStateAll (state) {
    state.all = []
  },

  insertTrainer (state, newTrainer) {
    let index = state.all.findIndex((trainer) => {
      return trainer.id === newTrainer.id
    })

    if (index === -1) {
      state.all.push(newTrainer)
    } else {
      state.all.splice(index, 1, newTrainer)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
