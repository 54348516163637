import Slider from "./slider"
// ####################
// ## SLIDERS
// ################################################################################
// # for each slider found in the markup, init one Slider
export default class Sliders {
  constructor () {
    const self = this

    this.attribute = 'data-is-slider'
    this.selector = `[${this.attribute}]`

    if ($(document).find(self.selector).length > 0) {
      self.init()
    }
  }

  init () {
    const self = this
    return $(self.selector).each(function () {
      let options = $(this).attr(self.attribute)

      if ((options !== null) && (options !== '') && (options !== undefined)) {
        options = JSON.parse(options)
      }

      return new Slider($(this), options)
    })
  }
}
