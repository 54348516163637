import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/populations",
  all: []
}

// getters
const getters = {
}

// actions
const actions = {
  getAllPopulations ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setPopulations', data.data))
  }
}

// mutations
const mutations = {
  setPopulations (state, populations) {
    state.all = populations
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
