/* eslint no-console:0 */
// MODULES =====================================================================
import Rails from 'rails-ujs'
import 'typeface-nunito'
import "bootstrap"
import "src/public/js/vue"
import 'lightgallery'
import 'lg-thumbnail'

import "src/stylesheets/application.sass"
import Tooltip from "@appComponents/helpers/tooltip"
import CustomFileInputList from "@appComponents/helpers/custom-file-input-list"
import ScrollTop from "@appComponents/helpers/scroll-top"
import ScrollTo from "@appComponents/helpers/scroll-to"
import NavFixed from "@appComponents/helpers/nav-fixed"
import NavSecondaryFixed from "@appComponents/helpers/nav-secondary-fixed"
import NavActive from "@appComponents/helpers/nav-active"
import HeaderFixed from "@appComponents/helpers/header-fixed"
import HomeLogo from "@appComponents/helpers/home-logo"
import InputSmooth from "@appComponents/helpers/input-smooth"
import ImageObjectFit from "@appComponents/helpers/image-object-fit"
import Sliders from "@appComponents/helpers/sliders"
import Lightbox from "@appComponents/helpers/light-box"
import MainMenu from "@appComponents/helpers/main-menu"
import Readmore from "@appComponents/helpers/readmore"
import SlidersPaginate from "@appComponents/helpers/sliders-paginate"
import StickyScroll from "@appComponents/helpers/sticky-scroll"
import MobileNav from "@appComponents/helpers/mobile-nav"
import "src/shared/flash-messages"
import "src/shared/interceptor"

// CONFIGURATION ===============================================================
Rails.start()

document.addEventListener("DOMContentLoaded", function (event) {
  new Tooltip()
  new InputSmooth()
  new ImageObjectFit()
  new CustomFileInputList()
  new NavActive()
  new NavSecondaryFixed()
  new NavFixed()
  new HeaderFixed()
  new HomeLogo()
  new ScrollTop()
  new ScrollTo()
  new Sliders()
  new Lightbox()
  new MainMenu()
  new Readmore()
  new SlidersPaginate()
  new StickyScroll()
  new MobileNav()
})

document.addEventListener("DOMContentLoaded", () => {
  $("[data-trigger-submit]").on("change", function (element) {
    $(this).closest('form').submit()
  })
})
