/* eslint no-undef: 0 */

// Required for IE11
import "@babel/polyfill"

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../../application/js/store'
import axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import Autocomplete from 'v-autocomplete'
import VueScrollTo from 'vue-scrollto'
import VueWaypoint from 'vue-waypoint'
import VueNumber from 'vue-number-animation'
import MapStyle from 'src/shared/resources/map-style.json'
import translations from "src/shared/resources/translations.yml"
import { merge } from 'lodash'

Vue.use(VueI18n)
Vue.use(Autocomplete)
Vue.use(VueScrollTo)
Vue.use(VueWaypoint)
Vue.use(VueNumber)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBUySeBLp3HPie6NQ87WOPX4THWcDboXfU',
    libraries: 'places,drawing'
  },
  installComponents: true
})

Vue.component('GmapCluster', GmapCluster)

const lang = document.querySelector('html').getAttribute('lang')
const i18n = new VueI18n({ locale: lang, messages: translations })

document.addEventListener('DOMContentLoaded', () => {
  let token = document.head.querySelector('meta[name="csrf-token"]').content
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common['Accept'] = 'application/json'
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  let app = document.querySelector('#vue_app')
  if (app != null) {
    new Vue({
      el: '#vue_app',
      store,
      i18n,
      components: {
        'Modal': () => import('@sharedComponents/Modal'),
        'Datepicker': () => import('@sharedComponents/Datepicker'),
        'NextAvailabilityBtn': () => import("@appComponents/NextAvailabilityBtn"),
        'HomeCareTimeSlots': () => import('@appComponents/HomeCareTimeSlots'),
        'AreaMap': () => import('@appComponents/AreaMap'),
        'ResultCard': () => import('@appComponents/ResultCard'),
        'BookingCalendar': () => import('@appComponents/BookingCalendar'),
        'SearchResult': () => import('@appComponents/SearchResult'),
        'AddressAutocomplete': () => import('@appComponents/AddressAutocomplete'),
        'SearchBar': () => import('@appComponents/SearchBar'),
        'SearchBarRounded': () => import('@appComponents/SearchBarRounded'),
        'NationalMap': () => import("@appComponents/NationalMap"),
        'Statistics': () => import('@appComponents/Statistics'),
        'ReviewWizard': () => import("@appComponents/ReviewWizard"),
        'AppointmentRequestWizard': () => import("@appComponents/AppointmentRequestWizard"),
        'CommentModal': () => import("@appComponents/CommentModal")
      },
      data () {
        // https://github.com/xkjyeah/vue-google-maps/issues/301
        // https://github.com/xkjyeah/vue-google-maps/issues/94
        // https://stackoverflow.com/questions/40896261/apply-global-variable-to-vuejs
        // https://github.com/xkjyeah/vue-google-maps/issues/315
        VueGoogleMaps.loaded.then(() => {
          this.configMap = merge(this.configMap, {
            zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_TOP
            }
          })
        })

        return {
          configMap: {
            styles: MapStyle,
            maxZoom: 19,
            disableDefaultUI: true,
            zoomControl: true
          },
          clusterStyles: [
            {
              textColor: 'black',
              url: require('images/map/map-cluster.png'),
              height: 50,
              width: 50
            }
          ]
        }
      },
      created: function () {
        const post = document.querySelector(".post")
        if (post && window.scrollY > 0) {
          window.scrollTo(0, 0)
        }
      }
    })
  }
})
