export default class ScrollTop {
  constructor () {
    this.$element = $('[data-is-scroll-top]')
    this.bindEvents()
  }

  bindEvents () {
    const self = this

    this.$element.click(function () {
      $('html, body').animate({ scrollTop: 0 }, 800)
      return false
    })

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        return self.$element.addClass('visible')
      } else {
        return self.$element.removeClass('visible')
      }
    })
  }
}
