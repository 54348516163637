import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/zones",
  all: [],
  current_zone: {
    latitude: '',
    longitude: '',
    radius: '',
    name: '',
    title: '',
    description_translations: '',
    published: '',
    position: '',
    city: '',
    switchboard: '',
    zone_type: ''
  }
}

// getters
const getters = {
}

// actions
const actions = {
  getAllZones ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setZones', data.data))
  },

  getZoneByAddress ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.put(state.endpoint.concat("/find_zone_by_address"), {
        localisation: params.localisation.attributes
      }).then(({ data }) => {
        let zone
        if (data.data) {
          zone = data.data
          commit('setCurrentZone', zone)
        } else {
          zone = data.message
        }
        commit('setCurrentZone', zone)
        resolve(zone)
      }).catch(({ response }) => {
        let zone = response.data.data
        reject(zone)
      })
    })
  }
}

// mutations
const mutations = {
  setZones (state, zones) {
    state.all = zones
  },

  setCurrentZone (state, zone) {
    state.current_zone = zone
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
