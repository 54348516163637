import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/sites",
  all: []
}

// getters
const getters = {
  hasSites (state) {
    return state.all.length > 0
  }
}

// actions
const actions = {
  getAllSites ({ commit }, params) {
    return axios.get(state.endpoint, { params }).then(({ data }) => commit('setSites', data.data))
  }
}

// mutations
const mutations = {
  setSites (state, sites) {
    state.all = sites
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
