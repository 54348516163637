// #############################
// CUSTOM FILE INPUT
// called in customFileInputList
// #############################
// # cf http://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/

export default class CustomFileInput {
  // ####### constructor: get elts + bind events
  constructor (inputWrapper) {
    // ##### get elts
    this.$input = inputWrapper.children('[data-is-custom-file-input]')
    this.$span = inputWrapper.children('[data-is-custom-file-input-message]')
    this.$spanVal = this.$span.html()

    // #### bind events
    this.bindEvents()
  }

  // ####### bind events : on change
  bindEvents () {
    const self = this
    // ### on change, change span text
    return this.$input.on('change', function (e) {
      let fileName = ''
      if (this.files && (this.files.length > 1)) {
        fileName = this.files.length + " fichiers sélectionnés"
      } else if (e.target.value) {
        fileName = e.target.value.split('\\').pop()
      }
      if (fileName) {
        const fileNameDisplayed = fileName.length <= 25 ? fileName : (fileName.substring(0, 25) + '...')
        self.$span.html('<span>' + fileNameDisplayed + '</span>')
      } else {
        self.$span.html('<span>' + self.$spanVal + '</span>')
      }
    })
  }
}
