import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/users",
  all: [],
  newUser: {
    id: null,
    attributes: {
      firstname: '',
      lastname: '',
      contact_email: '',
      user_full_name: '',
      errors: []
    }
  }
}

// getters
const getters = {
}

// actions
const actions = {
  getAllUsers ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setUsers', data.data))
  },

  resetStateAll (context) {
    context.commit('resetStateAll')
  },

  createUser ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.post(state.endpoint, {
        user: params.user.attributes
      }).then(({ data }) => {
        let user = data.data
        commit('insertUser', user)
        resolve(user)
      }).catch(({ response }) => {
        let user = response.data.data
        reject(user)
      })
    })
  }
}

// mutations
const mutations = {
  initializeNewUser (state, query) {
    if (query.indexOf('@') > -1) {
      state.newUser.attributes.contact_email = query
    } else {
      if (query.indexOf(' ') > -1) {
        state.newUser.attributes.firstname = query.substring(0, query.indexOf(' '))
        state.newUser.attributes.lastname = query.substring(query.indexOf(' ') + 1, query.length)
      } else {
        state.newUser.attributes.firstname = query
      }
    }
  },
  setUsers (state, users) {
    state.all = users
  },

  resetStateAll (state) {
    state.all = []
  },

  insertUser (state, newUser) {
    let index = state.all.findIndex((user) => {
      return user.id === newUser.id
    })

    if (index === -1) {
      state.all.push(newUser)
    } else {
      state.all.splice(index, 1, newUser)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
