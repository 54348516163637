import 'slick-carousel'
// ####################
// ## SLIDER
// ################################################################################
// # init one slider
export default class Slider {
  constructor (item, options) {
    this.item = item
    if (options == null) { options = {} }
    const defaultOptions = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 5000
    }

    this.options = $.extend(true, {}, defaultOptions, options)

    this.init()
  }

  init () {
    const self = this
    return this.item.slick(self.options)
  }
}
