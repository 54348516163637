import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/availabilities",
  all: [],
  newAvailability: {
    id: null,
    attributes: {
      date: '',
      start_time: '',
      end_time: '',
      practitioner_office_id: '',
      appointments_number: 1,
      errors: []
    }
  }
}

// getters
const getters = {
}

// actions
const actions = {
  getAllAvailabilities ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setAvailabilities', data.data))
  },

  createAvailability ({ commit }, availability) {
    return new Promise((resolve, reject) => {
      axios.post(state.endpoint, {
        availability: availability.attributes
      }).then(({ data }) => {
        availability = data.data
        commit('insertAvailability', availability)
        resolve(availability)
      }).catch(({ response }) => {
        availability = response.data.data
        reject(availability)
      })
    })
  },

  updateAvailability ({ commit }, availability) {
    axios.put(`${state.endpoint}/${availability.id}`, {
      availability: availability.attributes
    }).then(({ data }) => {
      availability = data.data
      commit('insertAvailability', availability)
    }).catch(({ response }) => {
      availability = response.data.data
    })
  },

  deleteAvailability ({ commit }, availability) {
    axios.delete(`${state.endpoint}/${availability.id}`)
      .then(() => {
        commit('deleteAvailability', availability)
        commit('setEditableAvailability', undefined)
      })
  },

  selectAvailability ({ commit }, availability) {
    commit('setEditableAvailability', availability)
  }
}

// mutations
const mutations = {
  setAvailabilities (state, availabilities) {
    state.all = availabilities
  },

  insertAvailability (state, newAvailability) {
    let index = state.all.findIndex((availability) => {
      return availability.id === newAvailability.id
    })

    if (index === -1) {
      state.all.push(newAvailability)
    } else {
      state.all.splice(index, 1, newAvailability)
    }
  },

  deleteAvailability (state, oldAvailability) {
    let index = state.all.findIndex((availability) => availability.id === oldAvailability.id)

    state.all.splice(index, 1)
  },

  setEditableAvailability (state, availability) {
    state.editableAvailability = availability
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
