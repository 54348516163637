import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/appointments",
  all: [],
  newAppointment: {
    id: null,
    attributes: {
      itelis: false,
      appointment_type: 'office',
      date: '',
      start_time: '',
      end_time: '',
      patient_id: '',
      office_id: '',
      patient_full_name: '',
      cancellation_motive: '',
      for_other_person: false,
      errors: [],
      practitioner_appointment_request_attributes: null,
      home_care_address: {
        attributes: {}
      }
    }
  }
}

// getters
const getters = {
}

// actions
const actions = {
  getAllAppointments ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setAppointments', data.data))
  },

  createAppointment ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.post(state.endpoint, {
        as: params.as,
        appointment: params.appointment.attributes
      }).then(({ data }) => {
        let appointment = data.data
        commit('insertAppointment', appointment)
        resolve(appointment)
      }).catch(({ response }) => {
        let appointment = response.data.data
        reject(appointment)
      })
    })
  },

  updateAppointment ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.put(`${state.endpoint}/${params.appointment.id}`, {
        appointment: params.appointment.attributes,
        cancel: params.cancel
      }).then(({ data }) => {
        let appointment = data.data
        if (params.cancel) {
          commit('removeAppointment', appointment)
        } else {
          commit('insertAppointment', appointment)
        }
        resolve(appointment)
      }).catch(({ response }) => {
        let appointment = response.data.data
        reject(appointment)
      })
    })
  }
}

// mutations
const mutations = {
  setAppointments (state, appointments) {
    state.all = appointments
  },

  insertAppointment (state, newAppointment) {
    let index = state.all.findIndex((appointment) => {
      return appointment.id === newAppointment.id
    })

    if (index === -1) {
      state.all.push(newAppointment)
    } else {
      state.all.splice(index, 1, newAppointment)
    }
  },

  removeAppointment (state, cancelledAppointment) {
    let index = state.all.findIndex((appointment) => appointment.id === cancelledAppointment.id)
    state.all.splice(index, 1)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
